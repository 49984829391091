.hoyatv__page {
	min-height: 100vh;
	padding: 2rem;
	padding-bottom: 15rem;
	position: relative;
	background: {
		color: $blue;
		image: url('../img/dots_header.svg');
		size: cover;
		position: right bottom; }
	#sticky-header {
		position: relative;
		width: 100%;
		max-width: 1088px;
		margin: 0 auto;
		padding: 2rem 0;
		text-align: right;
		.main-nav {
			.logo {
				width: 15rem;
				margin-left: auto; } } }

	.section {
		width: 100%;
		max-width: 1088px;
		margin: 0 auto;
		h1 {
			margin-bottom: 4rem;
			font-size: 4rem; }
		.iframes {
			display: block;
			margin-top: 2rem;
			.iframe--wrapper {
				width: 100%;
				height: 0;
				position: relative;
				padding-bottom: 56.25%; } } }
	footer {
		background: white;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%; }
	.login__form {
		background: white;
		padding: 3rem;
		width: 100%;
		max-width: 40rem;
		margin: 0 auto;
		.alert {
			font-size: 1.6rem; }
		h1 {
			color: $blue; }
		.form-group {
			display: block;
			margin-bottom: 2rem;
			label {
				display: block;
				font-size: 1.6rem; }
			input {
				padding: 1rem;
				font-size: 1.6rem;
				display: block;
				width: 100%; } }
		.btn-primary {
			background-color: $orange;
			font-size: 2rem;
			border-radius: 0;
			padding: 15px 50px;
			color: #fff;
			font-weight: bold;
			transition: all 0.3s ease 0s;
			text-transform: uppercase;
			border: 2px solid $orange;

			&:hover {
				background-color: transparent;
				color: $orange;
				text-decoration: none; } } } }
