* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html, body, * {
  font-size: 10px;
  line-height: 1.4;
  font-family: Arial, sans-serif; }

html {
  background: #ffffff; }

body {
  overflow-x: visible;
  transition: transform 300ms ease; }

.clearfix::after {
  content: "";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden; }

.inner--content {
  width: 100%;
  margin: 0 auto; }

img {
  max-width: 100%;
  display: block;
  height: auto; }

ul, li, ul li {
  list-style: none;
  margin: 0; }

p, strong {
  font-size: 1.6rem;
  line-height: 1.5;
  color: $p-font; }

//   @include desktop
//     font-size: 2.5rem
//     line-height: 3.5rem

html, body {
  background: #fff;
  font-family: 'Arial', sans-serif; }

::selection {
  background: $blue;
  color: #fff; }

::-moz-selection {
  background: $blue;
  color: #fff; }

@media (max-width: 991px) {
  html, body {
    overflow-x: hidden; } }

@media (min-width: 1400px) {
  .container {
    max-width: 1086px; } }

.alert {
	font-size: 1.6rem; }
