@mixin mobile {
  @media (max-width: $mobile) {
    @content; } }
@mixin mobile-min {
  @media (min-width: $tablet-start) {
    @content; } }
@mixin tablet {
  @media (max-width: $tablet) {
    @content; } }
@mixin tablet-only {
  @media (min-width: $tablet) and (max-width: $tablet-large) {
    @content; } }
@mixin desktop-medium-max {
  @media (max-width: $desktop-medium-low) {
    @content; } }
@mixin desktop-max {
  @media (max-width: $desktop-large-low) {
    @content; } }
@mixin desktop-only {
  @media (min-width: $desktop) and (max-width: $desktop-large) {
    @content; } }
@mixin desktop-small {
  @media (min-width: $desktop) and (max-width: $tablet-large) {
    @content; } }
@mixin desktop-medium {
  @media (min-width: $desktop-medium-low) {
    @content; } }
@mixin desktop {
  @media (min-width: $desktop) {
    @content; } }
@mixin desktop-large {
  @media (min-width: $desktop-large) {
    @content; } }
