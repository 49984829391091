#stream {
	background: $orange;
	padding: 0 0 0;
	background-image: url(../img/bg-header.svg);
	background-position: right;
	background-size: cover;
	background-repeat: no-repeat;
	h1 {
		color: #fff;
		font-weight: 700;
		font-family: galano_grotesque_demobold,sans-serif;
		font-size: 4rem;
		text-align: center;
		margin-bottom: 2rem;
		margin-top: 2rem;
		@media (max-width: 640px) {
			font-size: 2rem; } }
	.container {
		text-align: center; } }
#chat {
	background: #f5f5f5;
	padding: 2rem 0 0;
	text-align: center;
	.container {
		padding: 0; }
	iframe {
		margin: 0 auto;
		max-width: 100%!important; }
	h2 {
		color: $blue;
		font-weight: 700;
		font-family: galano_grotesque_demobold,sans-serif;
		font-size: 2rem;
		text-align: center;
		margin-bottom: 2rem;
		@media (max-width: 640px) {
			font-size: 2rem; } }
	.main-button {
		font-size: 14px;
		text-transform: none;
		margin-bottom: 4rem;
		padding: 0.5rem 1rem;
		margin: 0 1rem 1rem; } }

#programm {
	background: $blue;

	.container {
		padding: 2rem 1rem; }
	h2 {
		color: white;
		font-weight: 700;
		font-family: galano_grotesque_demobold,sans-serif;
		font-size: 6rem;
		text-align: center;
		margin-bottom: 2rem;
		@media (max-width: 640px) {
			font-size: 3rem; } }
	table {
		width: 100%;
		max-width: 70rem;
		margin: 0rem auto;
		tr {
			border-bottom: 1px solid #fff;
			td {
				color: white;
				// min-width: 15rem
				padding: 1rem 0;
				font-size: 2rem;
				em {
					margin-top: 0.5rem;
					font-size: 70%!important;
					line-height: 1.4!important;
					display: block; }
				@media (max-width: 640px) {
					font-size: 1.6rem; }
				* {
					font-size: 2rem;
					color: white;
					@media (max-width: 640px) {
						font-size: 1.6rem; } }
				&:first-child {
					max-width: 150px;
					display: none;
					@media (max-width: 640px) {
						max-width: 100px;
						min-width: 100px; } }
				&:last-child {
					max-width: 100%;
					width: 100%; } } } } }
.iframe--wrapper {
	width: 100%;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 0px;
	height: 0;
	box-shadow: 0 0 2rem rgba(black, 0.25); }

.videoiframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }


.stream-header {
	display: block!important;
	.logo {
		img {
			max-width: 30vw; } } }

#countdown {
	text-align: center;
	display: inline-block;
	margin: 4rem auto;
	font-size: 3rem;
	font-family: galano_grotesque_demobold,sans-serif;
	padding: 1rem 2rem;
	background: white;
	color: $blue;
	@media (max-width: 640px) {
		font-size: 2rem; }
	span {
		font-size: inherit;
		font-weight: inherit;
		font: inherit; } }

.grid--container {
	display: grid;
	grid-template-areas: 'programm video';
	grid-template-columns: 250px 2fr;
	// grid-gap: 2rem
	@media (max-width: 1200px) {
		grid-template-areas: 'video video' 'programm programm';
		grid-template-columns: 1fr 250px;
		grid-gap: 0; }
	@media (max-width: 960px) {
		grid-template-areas: 'video video' 'programm programm';
		grid-template-columns: 1fr 1fr; }
	@media (max-width: 560px) {
		grid-template-areas: 'video' 'programm';
		grid-template-columns: 1fr; }
	.main--content {
		grid-area: video;
		padding: 2rem;
 }		// @media (min-width: 1201px)
	#programm {
		grid-area: programm;
		text-align: left;
		* {
			text-align: left;
			font-size: 1.4rem; }

		h2 {
			font-size: 2rem; } }
	#chat {
		grid-area: chat; } }


.container.flex {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 80vh;
	h1 {
		// color: $blue!important
		max-width: 60rem; } }

.qrcode {
	display: block;
	margin-top: 2rem;
	color: white;
	text-align: center!important;
	font-weight: bold;
	transition: color 300ms ease;
	img {
		transition: transform 300ms ease;
		width: 100%;
		display: block;
		max-width: 12rem;
		margin: 2rem auto 0;
		transform-origin: center;
		transform: scale(1); }
	&:hover {
		color: rgba(white, 0.8);
		img {
			transform: scale(0.9); } } }

.logo {
	display: inline-block; }
.iframes {
	display: grid;
	grid-template-areas: "v1" "v2" "v3" "v4" "v5" "v6";
	grid-template-columns: 1fr;
	@media (min-width: 640px) {
		grid-template-areas: "v1 v2" "v3 v4" "v5 v6";
		grid-template-columns: 1fr 1fr; }
	grid-gap: 2rem;

	.iframe--wrapper {
		flex: 1 0 50%;
		&:nth-child(1) {
			grid-area: v1; }
		&:nth-child(2) {
			grid-area: v2; }
		&:nth-child(3) {
			grid-area: v3; }
		&:nth-child(4) {
			grid-area: v4; }
		&:nth-child(5) {
			grid-area: v5; }
		&:nth-child(6) {
			grid-area: v6; } } }
