a {
	&.main-button {
		font-size: 2rem;
		border-radius: 0;
		padding: 20px 20px;
		background-color: $blue;
		color: #fff;
		font-weight: bold;
		transition: all 0.3s ease 0s;
		text-transform: uppercase;
		border: 2px solid $blue;
		display: block;
		margin-bottom: 30px;
		text-align: center;

		// @include desktop
		// 	font-size: 2.5rem
		// 	padding: 30px 50px
		// 	margin-bottom: 0
 }		// 	display: inline-block

	&:hover {
		background-color: transparent;
		color: $blue;
		text-decoration: none; } }

.background-header, #sticky-header-mobile {
	background-color: rgba(255, 255, 255, 0.9);
	height: auto !important;
	position: fixed;
	top: 0;
	width: 100%;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15) !important;
	z-index: 99999;
	transition: all 0.3s ease 0s;

	img {
		max-width: 40%;
		@include desktop {
			max-width: 40%; }
		@include tablet-only {
			max-width: 40%; } } }

#sticky-header-mobile {
	.logo {
		float: left;
		display: inline-block;
		max-width: 130px; }

	.main-button {
		float: right;
		font-size: 1.4rem;
		padding: 15px 20px;
		margin-top: 15px;
		margin-right: 15px; }

	@include desktop {
		display: none; }
	img {
		max-width: 100%;
		padding-top: 30px;
		padding-left: 20px; } }

p {
	&.blue {
		color: $blue; } }

#header {
	@include desktop {
		display: flex; }
	background-color: #1B509B;
	img {
		&.desktop {
			display: none;

			@include desktop {
				display: block; } }

		&.mobile {
			display: block;
			@include desktop {
				display: none; } }

		width: 100%;
		height: auto; } }

#sticky {
	position: fixed;
	width: 348px;
	height: 80px;
	background-image: url("../img/sticky.svg");
	z-index: 9999;
	right: -265px;
	top: 50%;
	transform: translateY(-50%);
	transition: all 0.3s;
	&:hover {
		right: 0; } }

.divider {
	margin-top: 2rem;
	margin-bottom: 2rem;
	width: 55px;
	height: 4px;
	background-color: $blue;
	@include desktop {
		width: 95px;
		height: 8px; }

	&.center {
		margin: auto; } }

#welcome {
	.container {
		max-width: 1088px;
		position: relative;
		z-index: 1;
		padding-top: 8rem;
		padding-bottom: 10rem;

		h1 {
			font-weight: bold;
			font-size: 3.2rem;
			line-height: 4.3rem;
			margin-bottom: 2rem;
			display: block;
			color: #000000;
			@include desktop {
				font-size: 6.2rem;
				line-height: 7.3rem; } }

		h2 {
			color: $blue;
			font-size: 2.4rem;
			line-height: 3.5rem;
			margin-bottom: 4rem;
			max-width: 88.8rem;
			font-weight: bold;
			@include desktop {
				font-size: 3.4rem;
				line-height: 4.5rem; } }

		p {
			font-size: 1.8rem;
			line-height: 2.5rem;
			margin-top: 4rem;
			@include desktop {
				font-size: 2.5rem;
				line-height: 3.5rem; } } } }

#overview, .overview-area {
	background-color: #F8F8F8;
	padding-top: 10rem;
	padding-bottom: 10rem;
	.blue--heading {
		color: $blue;
		text-align: center;
		font-size: 2.5rem;
		line-height: 3.5rem;
		font-weight: bold; }


	.container {
		h2 {
			color: #000000;
			font-size: 2.4rem;
			line-height: 3.5rem;
			margin-bottom: 3rem;
			font-weight: bold;
			text-align: center;
			@include desktop {
				font-size: 3.4rem;
				line-height: 4.5rem; } }

		.blocks {
			@include desktop {
				display: flex;
				justify-content: space-between; }
			margin-bottom: 10rem;
			margin-top: 5rem;

			div {
				flex: 1;
				background-color: #ffffff;
				@include desktop {
					max-width: 317px; }
				padding: 50px 25px 25px;

				h3 {
					text-align: center;
					font-weight: bold;
					color: $blue;
					font-size: 2.1rem;
					line-height: 3.1rem;
					margin-bottom: 3rem; }

				p {
					text-align: center;
					font-size: 1.8rem;
					line-height: 2.8rem; } }

			& + h3 {
				color: $blue;
				text-align: center;
				font-size: 2.5rem;
				line-height: 3.5rem;
				font-weight: bold; } } } }

#registration {
	padding-top: 7rem;
	padding-bottom: 7rem;
	background-image: url(../img/dots_header.svg);
	background-position: right bottom;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: $blue;
	position: relative;
	@include desktop {
		padding-top: 15rem;
		padding-bottom: 15rem; }
	.container {
		max-width: 1088px;
		position: relative;
		z-index: 1; }

	.headline {
		position: relative;

		p {
			color: #ffffff; } } }

#management {
	padding-top: 13rem;
	padding-bottom: 13rem;

	.container {
		h2 {
			color: #000000;
			font-size: 2.4rem;
			line-height: 3.5rem;
			margin-bottom: 4rem;
			font-weight: bold;
			@include desktop {
				font-size: 3.4rem;
				line-height: 4.5rem; } }

		p {
			font-size: 1.8rem;
			line-height: 2.5rem;
			margin-top: 4rem;
			@include desktop {
				font-size: 2.5rem;
				line-height: 3.5rem; } } } }

#cta {
	background-color: $blue;
	padding-top: 6rem;
	padding-bottom: 6rem;

	.container {
		p {
			font-size: 1.8rem;
			line-height: 2.5rem;
			color: #ffffff;
			text-align: center;
			font-weight: bold;
			@include desktop {
				font-size: 2.5rem;
				line-height: 3.5rem; }

			a {
				font-size: 1.8rem;
				line-height: 2.5rem;
				color: $orange;
				@include desktop {
					font-size: 2.5rem;
					line-height: 3.5rem; } } } } }

#training {
	padding-top: 15.5rem;
	padding-bottom: 20rem;
	background-image: url(../img/bg-training.jpg);
	background-position: right center;
	background-size: cover;
	background-repeat: no-repeat;

	.container {

		p {
			max-width: 650px;
			font-size: 1.8rem;
			line-height: 2.5rem;
			@include desktop {
				font-size: 2.5rem;
				line-height: 3.5rem; } }

		a {
			margin-top: 5rem;
			max-width: 650px; } } }

header {
	@include tablet {
		display: none; }
	padding: 20px;
	@include desktop {
		padding: 20px 20px 20px 80px; } }

#MiYOSMART {
	background-color: $blue; }

#editorial {
	padding: 80px 0 80px;

	@include desktop {
		padding: 150px 0 140px; }

	@include tablet-only {
		img {
			margin-bottom: 40px; } }

	h1 {
		font-weight: bold;
		font-size: 3rem;
		line-height: 3.9rem;
		margin-bottom: 2rem;
		display: block;

		@include tablet {
			margin-top: 30px; }

		@include desktop {
			font-size: 5rem;
			line-height: 6.5rem; } }

	h2 {
		font-size: 2rem;
		line-height: 3rem;
		font-weight: bold;
		position: relative;
		margin-bottom: 100px;

		@include desktop {
			font-size: 3rem;
			line-height: 4.5rem; }

		&:after {
			content: '';
			height: 10px;
			background-color: $blue;
			width: 95px;
			position: absolute;
			left: 0;
			bottom: -50px; } }

	p {
		margin-bottom: 60px; } }

#testimonial-1,
#testimonial-2,
#testimonial-3 {
	background-color: $gray;
	padding: 80px 0;

	@include desktop {
		padding: 140px 0; }

	img {
		margin-bottom: 60px; }

	p {
		line-height: 2.5rem;
		@include desktop {
			line-height: 4rem; }
		em {
			font-size: 2rem;
			line-height: 2.5rem;
			@include desktop {
				font-size: 3rem;
				line-height: 4rem; } }
		& + p {
			em {
				font-size: 1.6rem;
				@include desktop {
					font-size: 2.5rem; } } } } }

#testimonial-2 {
	h3 {
		font-size: 3rem;
		line-height: 3.9rem;
		position: relative;
		margin-bottom: 100px;
		font-weight: bold;
		@include desktop {
			line-height: 4.5rem; }

		&:after {
			content: '';
			height: 10px;
			background-color: $blue;
			width: 95px;
			position: absolute;
			bottom: -50px;
			left: 50%;
			transform: translateX(-50%); } } }

#entertainment {
	background-image: url(../img/bg-entertaiment.png);
	background-position: top;
	background-size: 100%;
	background-repeat: no-repeat;

	@include desktop {
		background-position: 20%;
		background-size: 40%; }

	@include tablet-only {
		background-position: top; }

	.container {
		padding-top: 400px;
		padding-bottom: 80px;

		@include desktop {
			padding-top: 250px;
			padding-bottom: 250px; }

		@include tablet-only {
			padding-top: 310px;
			padding-bottom: 110px; }

		h2 {
			font-size: 3rem;
			line-height: 3.9rem;
			position: relative;
			margin-bottom: 100px;
			font-weight: bold;
			@include desktop {
				line-height: 4.5rem; }

			&:after {
				content: '';
				height: 10px;
				background-color: $blue;
				width: 95px;
				position: absolute;
				bottom: -50px;
				left: 0; } } } }

#MiYOSMART {
	padding: 200px 0 80px;
	@include desktop {
		padding: 150px 0 140px; }
	position: relative;

	background-image: url(../img/bg-MiYOSMART.svg);
	background-position: right bottom;
	background-size: 50%;
	background-repeat: no-repeat;

	.stoerer {
		position: absolute;
		top: 0;
		@include desktop {
			right: 140px; }
		@include tablet {
			left: 50%;
			transform: translateX(-50%); } }

	h2 {
		color: #ffffff;
		font-weight: bold;
		font-size: 3rem;
		line-height: 3.9rem;
		margin-bottom: 100px;
		position: relative;

		&:after {
			content: '';
			height: 10px;
			background-color: $blue;
			width: 95px;
			position: absolute;
			bottom: -50px;
			left: 0; }

		@include desktop {
			font-size: 5rem;
			line-height: 6.5rem; } }

	p {
		color: #ffffff;
		margin-bottom: 60px; } }

#form {
	position: relative;


	form {
		.form-group {
			margin-bottom: 2rem;

			p {
				font-size: 1.8rem;
				margin-top: 3rem;
				margin-bottom: 4rem; } }

		strong {
			color: $orange;
			font-size: 2.5rem; }
		p {
			font-weight: normal!important;
			line-height: 1.4; } }

	h2, span {
		font-weight: bold;
		margin-bottom: 35px;
		font-size: 3rem;
		line-height: 3.9rem;
		color: #ffffff;

		&.orange {
			color: $orange; }

		@include desktop {
			font-size: 6rem;
			line-height: 7.3rem; } }

	p {
		color: #ffffff;
		font-size: 2rem;
		line-height: 3rem; }

	label {
		color: #ffffff;
		font-size: 1.8rem;
		line-height: 2.1rem;

		a {
			color: $orange;
			font-size: 1.8rem;
			line-height: 2.1rem; } }

	.stoerer {
		position: absolute;
		top: 0;
		@include desktop {
			right: 140px; }
		@include tablet {
			left: 50%;
			transform: translateX(-50%); }
		img {
			@include tablet-only {
				max-width: 80%; } } }

	.form-control {
		padding: 2.4rem .75rem;
		font-size: 2rem;
		color: $p-font;
		border-radius: 0;
		background-color: #fff;
		background-clip: unset;
		border: none;
		transition: none; }

	[type="radio"] {
		&:checked, &:not(:checked) {
			position: absolute;
			left: -9999px; }

		&:checked + label, &:not(:checked) + label {
			position: relative;
			padding-left: 30px;
			cursor: pointer;
			line-height: 26px;
			display: inline-block;
			color: #ffffff; }

		&:checked + label:before, &:not(:checked) + label:before {
			content: '';
			position: absolute;
			left: -13px;
			top: 0;
			width: 30px;
			height: 30px;
			border: 1px solid $blue;
			border-radius: 100%;
			background: #fff; }

		&:checked + label:after {
			content: '';
			width: 10px;
			height: 10px;
			background: $blue;
			position: absolute;
			top: 10px;
			left: -3px;
			border-radius: 100%;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;

			&:not(:checked) + label:after {
				content: '';
				width: 10px;
				height: 10px;
				background: $blue;
				position: absolute;
				top: 10px;
				left: -3px;
				border-radius: 100%;
				-webkit-transition: all 0.2s ease;
				transition: all 0.2s ease;
				opacity: 0;
				-webkit-transform: scale(0);
				transform: scale(0); } }

		&:checked + label:after {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1); } }

	.btn-primary {
		background-color: $orange;
		font-size: 2.8rem;
		border-radius: 0;
		padding: 19px 77px;
		color: #fff;
		font-weight: bold;
		transition: all 0.3s ease 0s;
		text-transform: uppercase;
		border: 2px solid $orange;
		margin-top: 3rem;

		&:hover {
			background-color: transparent;
			color: $orange;
			text-decoration: none; } } }

footer {
	p {
		margin-top: 1rem; }
	p, a {
		color: $p-font;
		font-size: 1.8rem;
		line-height: 3.5rem; } }
.info__box {
	width: 100%;
	max-width: 27rem;
	padding: 2rem;
	text-align: center;
	background: $orange;
	margin: 0 auto 2rem;
	p, strong {
		margin: 0!important;
		padding: 0!important;
		color: white;
		font-size: 2rem; } }

.header-area {
	padding: 0;
	width: 100%;
	max-width: 1088px;
	margin: 4rem auto 0;
	z-index: 0;
	@media (min-width: 1600px) {
		position: absolute;
		max-width: 100%;
		left: 0;
		bottom: 0;
		padding: 0 0 3rem 2rem; } }

.image__row {
	margin-top: 6rem;
	@media (max-width: 991px) {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		margin-top: 4rem;
		.info__box {
			margin: 0 2rem 0 0;
			max-width: 100%;
			p, strong {
				font-size: 1.6rem; } } }
	@media (max-width: 430px) {
		display: block;
		.info__box {
			margin-bottom: 2rem; }
		.image {
			width: 100%;
			img {
				width: 100%;
				height: auto; } } } }
.btn-primary {
	background-color: $orange;
	font-size: 2rem;
	border-radius: 0;
	padding: 15px 50px;
	color: #fff;
	font-weight: bold;
	transition: all 0.3s ease 0s;
	text-transform: uppercase;
	border: 2px solid $orange;

	&:hover {
		background-color: transparent;
		color: $orange;
		text-decoration: none; } }
