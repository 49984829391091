.imprint {

	#welcome {
		padding: 180px 0 80px;
		margin-bottom: 20px;

		background-position: center;

		@include desktop {
			padding: 160px 0 60px;
			margin-bottom: 0; }

		h1 {
			color: #ffffff;
			font-weight: bold;
			font-family: 'galano_grotesque_demobold', sans-serif;
			font-size: 5rem;
			line-height: 8rem;
			@include desktop {
				font-size: 6rem;
				line-height: 10rem; } } }

	#editorial {
		p {
			margin-bottom: 1rem; }

		p, strong, a {
			font-size: 1.8rem;
			line-height: 2.6rem;
			color: white!important; } }

	#sticky-header-mobile {
		display: block;
		.logo {
			float: left; }

		.main-button {
			float: right;
			font-size: 1.4rem;
			padding: 15px 20px;
			margin-top: 15px;
			margin-right: 15px; }
		img {
			max-width: 60%;
			padding-top: 30px;
			padding-left: 20px; } } }
