h1 {
	font-size: 6rem;
	color: white;
	font-weight: bold;
	margin-bottom: 3rem;
	@include tablet {
		font-size: 4rem; }
	span {
		font: inherit;
		color: $orange;
		@media (min-width: 600px) {
			white-space: nowrap; } } }

p {
	font-weight: normal; }
